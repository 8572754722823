<template>
  <div class="container mypage withTop">
    <section class="contents farmList">
      <article class="inner">
        <ul v-if="farmList.length > 0">
          <router-link :to="{ name: 'farmDetail', query: { id: data._id } }" tag="li" class="box" v-for="(data, i) in farmList" :key="i">
            <h4>{{ data.farmName }}</h4>
            <p><span>주소</span>{{ data.address }}</p>
            <p><span>작물</span>{{ data.crops }}</p>
          </router-link>
        </ul>
        <p class="nodata" v-else>농장을 등록해 주세요.</p>
      </article>
    </section>
    <router-link to="/mypage/farmRegister" tag="button" class="floatingBtn" v-if="userType != 'EXAMINER'">
      <span class="material-icons-round"> add </span>
    </router-link>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(["userId", "farmList", "userType"]),
  },
  created() {
    this.$store.dispatch("SET_MENU_VISIBLE", true);
    this.$store.dispatch("SET_NAVBAR_VISIBLE", true);
    this.$store.dispatch("SET_PAGE_NAME", "농장관리");
  },
  methods: {
    openAlert(msg) {
      this.$alert(msg, {
        confirmButtonText: "확인",
        center: true,
      });
    },
  },
};
</script>
